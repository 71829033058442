.karrier-gyorsjelentkezes {
  background: #f7f7f7;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #f7f7f7;
  }
}

.karrier-gyorsjelentkezes-ikon {
  border: 3px solid $brand-primary;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 70px !important;
  }
}

.karrier-gyorsjelentkezes-pozicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 33%;
}

.karrier-gyorsjelentkezes-title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1em;
  height: 14px * $headings-line-height * 2;
  display: flex;
  align-items: center;
}

.karrier-gyorsjelentkezes-tovabbi-btn {
  font-size: 14px;
  margin-top: 70px;
}

.karrier-ajanlo-btn {
  font-size: 14px;
}

.mitnyujt-elem {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.mitnyujt-title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.allas-lista {
  .border-bottom {
    border-bottom: 1px solid #d5d5d5;
    &:last-child, &.utolso:nth-last-child(2) {
      border: none;
    }
  }

  .allas-lista-kategoria {
    padding: 5px 10px 15px;

  }

  .allas-lista-elem {
    margin: 5px 0;
    a {
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      &:focus, &:hover {
        color: $brand-success;
      }
    }
  }

  .allas-kategoria-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 3px;
      background: #00b98b;
    }
  }
}

.oldalsav-almenupontok {
  a {
    display: flex;
    align-items: center;
    margin: 20px 0;
    h2 {
      font-size: 14px;
      margin: 0;
      transition: all 0.25s ease-in-out;
      flex-grow: 1;
      text-transform: uppercase;
    }
  }
}

.karrier-aloldal-oldalsav {
  position: relative;
  padding-top: 2px;
  padding-bottom: 10px;
  /*&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 3px;
    background: #00b98b;
  }*/
}