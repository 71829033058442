/* saját form css, mint pl. egyedi checkbox és radio */

.control-label, label {
	font-weight: 500;
	text-align: left !important;
}

input[type=radio],
input[type=checkbox] {
	opacity: 0;
	position: absolute;
}

.form-control {
	box-shadow: none;
}

/* használata: input[type=checkbox] + label.checkbox-label */

.checkbox-label {
	margin: 0;
	display: block;
	color: $text-color;
	font-weight: 500;
	position: relative;
	padding-left: $line-height-computed + 7px;
	cursor: pointer;
	@include user-select(none);

	&:before {
		content: '';
		top: 0;
		left: 0;
		width: $line-height-computed - 4px;
		height: $line-height-computed - 4px;
		display: block;
		position: absolute;
		background: $input-bg;
		border: 2px solid $brand-success;
		line-height: 14px;
		font-size: 20px;
		text-align: center;
		color: $brand-success;
	}

	input[type=checkbox]:checked + &:before {
		content: "\00d7";
	}
}

/* használata: input[type=radio] + label.radio-label */

.radio-label {
	margin: 0;
	display: block;
	color: $text-color;
	font-weight: 500;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	min-height: 28px;
	margin-bottom: 5px;
	@include user-select(none);

	&:before {
		content: '';
		top: 4px;
		left: 0;
		width: 22px;
		height: 22px;
		display: block;
		position: absolute;
		border: 5px solid #eaebe7;
		border-radius: 50%;
	}

	input[type=radio]:checked + &:before {
		background: $brand-primary;

	}
}