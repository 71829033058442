.hir-listaelem-kep {
  flex: 0 0 160px;
  margin-right: 20px;
}

.hir-listaelem-cim {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: none;

  > a:hover {
    color: $brand-success;
  }
}

.hir-listaelem-datum {
  color: #999;
  font-size: 12px;
  background: url("../../images/icon-calendar.png") 0 50% no-repeat;
  padding-left: 25px;
  margin-bottom: 10px;
  display: block;
}

.hir-listaelem {
  margin-bottom: 40px;
}