.dokumentum-listaelem {
	display: flex;

	.ikon-file-download {
		margin-right: 20px;

		path {
			fill: $brand-primary;
			@include transition(all .25s ease-in-out);
		}
	}

	&+& {
		margin-top: 30px;
	}

	&:hover {
		.dokumentum-listaelem-nev {
			color: $brand-success;
		}

		path {
			fill: $brand-success;
		}
	}
}

.dokumentum-listaelem-nev {
	font-size: 20px;
	text-transform: none;
	margin: 0;
	@include transition(all .25s ease-in-out);
}

.dokumentum-listaelem-leiras {
	font-size: $font-size-base;
	color: $text-color;
	margin-bottom: 0;
}
