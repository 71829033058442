.timeline {
	list-style: none;
	padding: 20px 20px 20px 40px;
	background: #f3f3f3;
}

.timeline-elem {
	position: relative;
	padding: 0 0 10px 40px;
	border-left: 6px solid $brand-primary;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #f3f3f3;
		box-shadow: 0 0 20px rgba(0,0,0,.2);
		border: 6px solid $brand-success;
		@include translate(calc(-50% - 3px), 0);
	}
}

.timeline-idopont {
	margin-top: 0;
	color: $brand-success;
	margin-left: -40px;
	padding-left: 40px;
	background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 70%);
	line-height: 30px;
}

.timeline-cim {
	text-transform: none;
	font-size: 16px;
}