.header {
	background: #f7f7f7;
}

.header-menusav {
	padding: 0 40px;
	position: absolute;
	z-index: 10;
	right: 0;
	background: transparentize(#f7f7f7, .1);

	@media (min-width: $grid-float-breakpoint) {
		left: 0;
	}

	.fooldal & {
		position: static;
		right: auto;
		left: auto;
		background: transparent;
	}

	.dropdown-menu {
		margin: 0;
		border: 0;
		background: transparentize(#f7f7f7, .1);
	}
}

.header-fomenu {
	margin: 0;
}

.header-fomenupont {
	padding: 20px;
	font-size: 16px;
	font-family: $headings-font-family;
	text-transform: uppercase;

	>span {
		color: $link-color;
	}
}

.header-fomenupont-toggle {
	display: none;
}

@media (min-width: $grid-float-breakpoint)  {
	.header-fomenupont.dropdown:hover {
		.dropdown-menu {
			display: block;
		}
	}
}

.header-fomenupont.active {
	//border-bottom: 6px solid $brand-success;
	position: relative;

	>a, >span {
		color: $brand-success;
	}

	/*
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: calc(50% - 10px);
		width: 0;
		height: 0;
		border: 10px solid transparent;
		border-bottom-color: $brand-success;
	}
	*/
}

.header-lang-select {
	margin: 0;
	display: flex;
	align-items: center;
}

.header-lang {
	padding: 0 10px;
	font-family: $headings-font-family;
	text-transform: uppercase;
	line-height: 1;
	height: 14px;

	a {
		color: $brand-primary;
	}

	&.active a, a:hover, a:focus {
		color: $brand-success;
	}
}

.fooldal {
	.header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		background: transparent;
	}

	.header-fomenupont>a,
	.header-fomenupont>span, {
		color: #fff;
	}

	.header-lang-select {
		padding: 15px 10px;
		align-self: flex-start;
	}

	.header-lang {
		&+.header-lang {
			border-left: 2px solid $gray-lighter;
		}

		a {
			color: $gray-lighter;
		}

		&.active a, a:hover, a:focus {
			color: #fff;
		}
	}
}

.aloldal-header {
	min-height: 150px;
	position: relative;
}

.aloldal-header-title {
	background: #fff;
	font-size: 26px;
	text-transform: uppercase;
	position: absolute;
	top: 100%;
	left: 50%;
	margin: 0;
	padding: .5em 1em;
	text-align: center;
	@include translate(-50%, -50%);

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: calc(50% - 50px);
		width: 100px;
		height: 3px;
		background: $brand-success;
	}
}

.header-almenupont>a {
	color: $text-color;
}

.header-almenupont.active>a {
	color: $brand-success;
	background: #f6f6f6;
}