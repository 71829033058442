.szolgaltatas-blokk {
  font-size: 14px;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.alblokk {
  padding-left: 30px;
}

.blokk-title {
  font-size: 18px;
  color: $brand-primary;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;

  > a:hover {
    color: $brand-success;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 3px;
    background: $brand-success;
  }

  a[aria-expanded=true] .blokk-caret {
    @include scaleY(-1);
  }
}

.blokk-galeria-kep {
  flex: 0 0 calc(100% / 3 - 15px);
  margin: 10px 0;
}

.blokk-cikk {
  margin-top: -10px;
  h1, h2, h3, h4, h5, h6 {
    //font-size: 20px;
    text-transform: none;
  }

  table {
    @extend .table;
    @extend .table-striped;
    border: 0 !important;
  }

  ul, ol {
    padding-left: 25px;
  }
}

.blokk-hir,
.blokk-dokumentum,
.blokk-szoveg {
  margin-top: 20px;
  background: #f7f7f7;
  padding: 20px;
}

.blokk-hir-kep {
  flex: 0 0 160px;
  margin-right: 20px;
}

.blokk-hir-cim,
.blokk-szoveg-cim {
  font-size: 20px;
  margin-top: 0;

  > a:hover {
    color: $brand-success;
  }
}

.blokk-caret {
  @include transform-origin(50% 50%);
  @include transition(all .3s);
  @include scaleY(1);
}
