@import "//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;subset=latin-ext";

$fonts: "../../../fonts/";

@font-face {
	font-family: 'nexa';
	src: url('#{$fonts}nexabold_0-webfont.woff2') format('woff2'),
	url('#{$fonts}nexabold_0-webfont.woff') format('woff'),
	url('#{$fonts}nexabold_0-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}