@media (max-width: $grid-float-breakpoint-max) {
	.fooldal-slider-btns {
		width: 100vw;
		margin: 1px 0;
	}

	.fooldal-slider-btn {
		flex-grow: 1;
      margin: .5rem auto .5rem auto;
      width: 75%;
      &:first-of-type{
        margin: .5rem auto 0 auto;
      }
	}

	.fooldal-slider-cover {
		background-size: 40vw auto;
	}

	.header-menusav {
		position: fixed !important;
		right: 100vw !important;
		width: 100vw;
		top: 0;
		bottom: 0;
		overflow-y: auto;
		flex-direction: column;
		background: transparentize($brand-primary, .04) !important;
		z-index: 1000;
		padding: 20px;

		@include transition(all .3s ease-in-out);

		&.open {
			right: 0 !important;
		}

		.header-menusav-toggle {
			padding: 0;
		}

		.dropdown-menu {
			right: 0;
			min-width: auto;
			float: none;
			margin-top: 18px;

			>li>a {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}

	.header-menusav-toggle {
		color: #fff;
		font-size: 3em;
		padding: .5em;
		float: right;
		opacity: .8;
		z-index: 10;

		.header-menusav+& {
			color: $brand-primary;
		}

		.fooldal .header-menusav+& {
			color: #fff;
		}
	}

	.header-fomenu {
		flex-direction: column;
		order: 2;
	}

	.header-fomenupont {
		border-top: 1px solid transparentize(#fff, .6);
		padding-left: 0;
		padding-right: 0;

		a, span {
			color: #fff;
		}

		&.active {
			border-bottom: 0 none;

			&:after {
				display: none;
			}
		}
	}

	.header-fomenupont-toggle {
		position: absolute;
		display: block;
		right: 0;
		top: 10px;
		width: 60px;
		height: 40px;
		border-left: 1px solid rgba(255,255,255,.2);
		background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 10' width='20' height='10'%3E%3Cpolyline fill='none' stroke='rgba%28255,255,255,.6%29' stroke-linecap='butt' points='0.5,0.5 10,9 19.5,0.5'/%3E%3C/svg%3E") 50% 50% no-repeat;
	}

	.header-lang-select {
		padding: 20px 0;
	}

	.header-lang:first-child {
		padding-left: 0;
	}

	.header-lang {
		a {
			color: #fff;
		}
	}

	.aloldal-header-logo {
		display: none;
	}

	.aloldal-header-title {
		font-size: 22px;
	  	width: 90%;
	}

	.header {
		background-image: url("../../images/transsped-logo-aloldal.png");
		background-position: 25px 25px;
		background-repeat: no-repeat;
	}

	.fooldal {
		.header {
			background-image: none;
		}
	}

	.menedzsment-listaelem-kep>img {
		margin: 0 auto;
	}

	.aloldal-oldalsav {
		margin-top: 3em;
	}

	.aloldal-gyorsjelentkezes-ikon {
		width: 70px;
		height: 70px;
		flex: 0 0 70px;
		margin-right: 15px;

		img {
			height: 35px;
		}
	}

	.footer {
		font-size: 14px;
		padding: 20px $grid-gutter-width / 2;
	}

	.hir-listaelem {
		padding: 0 $grid-gutter-width / 2;
	}

	.hir-listaelem-kep {
		align-self: center;
		margin: 0 0 15px;
		flex: 0 0 auto;
	}

	.fooldal-szolgaltatas {
		margin-bottom: 2em;
	}

  .blokk-title{
    font-size: 16px;
  }
  .aloldal-cikk-cim, .oldalsav-cim{
    font-size: 16px;
  }
}