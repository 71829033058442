@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
	.fooldal-kiket-keresunk-header {
		justify-content: center;

		p:last-child {
			text-align: center;
		}
	}

	.fooldal-kiket-keresunk-kep {
		display: none;
	}
}