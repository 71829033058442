.modal-content {
	background: #f7f7f7;

	.close {
		font-size: 2em;
	}

	.control-label,
	.form-control {
		font-family: $headings-font-family;
		text-transform: uppercase;
		color: #686868;
	}

	.form-control {
		border: 1px solid #e7e7e7;
		border-radius: 4px;
	}

	.has-error {
		@include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);
	}
}

.modal-title {
	font-size: 24px;
	padding-top: 70px;
	text-align: center;
	background: url("../../images/transsped-logo-aloldal.png") 50% 0 no-repeat;
}

/*
.modal-body {
	padding-left: 70px;
	padding-right: 70px;
}
*/

.modal-header {
	border: 0;
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 1px;
		@include gradient-horizontal-three-colors(#f0f0f0, #8c8b8b, 50%, #f0f0f0);
	}
}