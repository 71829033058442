@media (max-width: $screen-xs-max){
	.fooldal-slider {
		display: flex;
		flex-direction: column;
	}

	.fooldal-slider-cover {
		position: static;
		order: 2;
		background: $brand-primary;

		+.owl-carousel {
			order: 1;
		}
	}

	.fooldal-slider-btn {
		font-size: 14px;
	}

	.fooldal-slide {
		height: 50vw;

		img {
			height: 100%;
			width: auto !important;
		}
	}

	.fooldal-section-title {
		font-size: 5vw;
	}

	.fooldal-szolgaltatas {
		margin-bottom: 1em;
	}

	.fooldal-szolgaltatas-nev {
		font-size: 4vw !important;
	}

	.fooldal-szolgaltatasaink-masodiksor {
		margin-top: 0;
	}

	.fooldal-kiket-keresunk-kep {
		display: none;
	}

	.fooldal-kiket-keresunk .fooldal-section-title {
		margin-top: 50px;
		text-align: center;

		&:after {
			left: calc(50% - 50px);
		}
	}

	.fooldal-kiket-keresunk-lead {
		width: auto;
		padding: 20px;
		font-size: $font-size-base;
	}

	.fooldal-gyorsjelentkezes-pozicio {
		margin-bottom: 3em;
		flex-basis: auto;
	}

	.fooldal-telephelyeink {
		overflow: hidden;
	}

	.fooldal-telephely-listak header {
		height: auto;
		margin-bottom: 1em;
	}

	.fooldal-gyorsjelentkezes-tovabbi-btn {
		font-size: 4vw;
		margin-top: 3em;
	}

	.footer-fomenu {
		margin-top: 15px;
	}

	.footer-fomenupont {
		padding: 15px 0;
		border-top: 1px solid rgba(255,255,255,.3);
	}

	.aloldal-content {
		flex-direction: column;
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}

	.aloldal-cikk,
	.aloldal-oldalsav {
		flex: 0 0 auto;
	}

	.oldalsav-cim {
		text-align: center;

		&:after {
			left: calc(50% - 50px);
		}
	}

	.terkep-telephely-xs {
		margin-top: 1em;
		border-top: 1px solid #bbb;
		padding-top: 1em;

		&:first-child {
			border-top: 0;
		}

		.terkep-telephely-szolgaltatasok {
			margin: 10px 0 10px;
			padding: 0 0 10px;
			border-bottom: 1px solid #ddd;
		}
	}

	.terkep-telephely-xs-nev {
		margin: 0;
		font-size: 1.25em;
	}

	.terkep-telephely-xs-text:first-child {
		padding-top: 10px;
	}

	.terkep-telephely-xs-text:last-child .terkep-telephely-szolgaltatasok {
		border: 0;
	}

	.infografika-termlog {
		&-lepes:before {
			display: none;
		}

		&-picture {
			margin-bottom: .5em;
			margin-right: 0;
		}

		&-caption {
			text-align: center;
		}
	}

	.karrier-gyorsjelentkezes-pozicio {
		flex-basis: auto;
		margin-bottom: 2em;
	}

	.mitnyujt-elem,
	.aloldal-cikk:only-child,
	.aloldal-gyorsjelentkezes-pozicio {
		flex-basis: auto;
	}

	.fooldal-szolgaltatasaink .fooldal-szolgaltatas-ikon {
		height: 80px !important;
	}

	.dropdown-menu > li > a {
		white-space: normal;
	}
}