.headings-font {
	font-family: $headings-font-family;
}

.btn {
	font-family: $headings-font-family;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 16px;
	border-width: 3px;
}

.btn-success:hover {
	color: darken($btn-success-color, 12%);
}

a {
	@include transition(all .25s ease-in-out);
}

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	color: $brand-primary;
}

.jelentkezes-modal {
	.modal-content {
		background: #f7f7f7;
	}

	.close {
		font-size: 3em;
		line-height: .5;
	}
}

.footer {
	background: $brand-primary;
	color: $footer-font-color;
	padding: 20px 80px;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	position: relative;

	a {
		color: $footer-font-color;
	}

	a:hover, a:focus {
		color: darken($footer-font-color, 10%);
	}
}

.footer-elerhetoseg {

}

.footer-logo {
	margin-bottom: 1em;
}

.footer-fomenu {
	margin: 0;
}

.footer-fomenupont {
	padding: 20px;
	font-size: 16px;
	font-family: $headings-font-family;
	text-transform: uppercase;
}

.aloldal-content {
	justify-content: space-between;
	padding: 110px 0 50px;
}

.aloldal-cikk {
	flex: 0 0 66%;

	&:only-child {
		flex-basis: 100%;
	}

	figcaption {
		font-style: italic;
	}

	img {
		max-width: 100%;
		height: auto !important;
	}
}

.aloldal-oldalsav {
	flex: 0 0 25%;

	&:empty {
		display: none;
	}

	> section + section {
		margin-top: 60px;
	}

	.active {
		color: $brand-success;
	}
}

.aloldal-oldalsav-cim {
	font-size: 16px;
	padding-bottom: .5em;
	margin: 0 0 1em;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100px;
		height: 3px;
		background: $brand-success;
	}
}

.aloldal-cikk-cim {
	font-size: 18px;
	padding-bottom: .5em;
	margin: 0 0 1em;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100px;
		height: 3px;
		background: $brand-success;
	}
}

.oldalsav-cim {
	@extend .aloldal-cikk-cim;
	font-size: 16px;
}

.aloldal-gyorsjelentkezes-ikon {
	border: 3px solid $brand-primary;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	display: flex;
	margin-right: 25px;
	align-items: center;
	justify-content: center;
	flex: 0 0 100px;

	img {
		height: 50px;
	}
}

.aloldal-gyorsjelentkezes-pozicio {
	display: flex;
	align-items: center;
	margin: 1em 0;
}

.aloldal-gyorsjelentkezes-title {
	font-size: 14px;
	margin: 0 0 1em;
}

.aloldal-gyorsjelentkezes-tovabbi-btn {
	font-size: 14px;
	margin-top: 40px;
}

.aloldal-ajanlakeres-btn{
	font-size: 18px;
	margin-top: 25px;
	display: block;
}

hr {
	margin-top: 40px;
	margin-bottom: 40px;
}

.aloldal-oldalsav-szolgaltatas {
	margin: 20px 0;

	svg * {
		@include transition(all .25s ease-in-out);
	}

	&:hover, &:focus {
		.aloldal-oldalsav-szolgaltatas-nev {
			color: $brand-success;
		}

		svg path {
			fill: $brand-success;
		}

		svg line,
		svg polyline,
		svg polygon,
		svg path,
		svg rect,
		svg ellipse,
		svg .st0 {
			stroke: $brand-success !important;
		}
	}

	&:nth-last-of-type(1){
		.aloldal-oldalsav-szolgaltatas-kep {
			svg{
				/* transform: scale(1.5);
				position: relative;
				right: 5px; */
			}
		}
	}
}

.aloldal-oldalsav-szolgaltatas-kep {
	flex: 0 0 50px;
	margin-right: 10px;

	img{
		width: 100%;
	}
}

.aloldal-oldalsav-szolgaltatas-nev {
	font-size: 14px;
	margin: 0;
	@include transition(all .25s ease-in-out);
}

.cikk-header-title {
	background: #fff;
	font-size: 22px;
	text-transform: uppercase;
	position: relative;
	margin: 0 0 1em;
	padding: .5em 1em;
	text-align: center;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: calc(50% - 50px);
		width: 100px;
		height: 3px;
		background: $brand-success;
	}
}

.youtube-embed-wrapper {
	margin-bottom: 1em;
}

.img-margin {
	margin-left: 10px;
	margin-bottom: 10px;
}

.collapsed {
	.pulse {
		animation: pulse 3.5s infinite;
		animation-timing-function: ease-out;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
		transform: scaleX(1);
	}
	10% {
		opacity: 1;
		transform: scaleX(0.98);
	}
	20% {
		opacity: 0.8;
		transform: scaleX(0.96);
	}
	30% {
		opacity: 0.7;
		transform: scaleX(0.94);
	}
	40% {
		opacity: 0.6;
		transform: scaleX(0.92);
	}
	50% {
		opacity: 0.4;
		transform: scaleX(0.9);
	}
	60% {
		opacity: 0.6;
		transform: scaleX(0.92);
	}
	70% {
		opacity: 0.8;
		transform: scaleX(0.94);
	}
	80% {
		opacity: 0.95;
		transform: scaleX(0.97);
	}
	90% {
		opacity: 1;
		transform: scaleX(0.99);
	}
	100% {
		opacity: 1;
		transform: scaleX(1);
	}
}

.footer-sm {
	margin: 10px;

	&-link {
		width: 2em;
		height: 2em;
		border-radius: 50%;
		margin: 10px;
		z-index: 10;

		> svg {
			width: 100%;
			height: 100%;
		}

		path {
			fill: $footer-font-color;
			transition: all .5s ease-out;
		}

		&:hover {
			transform: scale(1.25);
			transition: background-color .5s, transform .5s ease-out;
			background-color: $footer-font-color;
		}
	}

	.facebook:hover path {
		fill: #3b5999;
	}

	.linkedin:hover path {
		fill: #0077b5;
	}

	.youtube:hover path {
		fill: #cd201f;
	}
}

.blokk-tovabb {
	color: $brand-success;

	&[aria-expanded=true] {
		opacity: 0;
	}

	&[aria-expanded=false] {
		opacity: 1;
	}
}

.IN-widget:not(:first-child) {
	margin-top: 2em;
}

html[lang=en] {
	.fooldal-slider-btn {
		font-size: 16px;
	}
}

/*
@media only screen and (min-width: 1200px){
	.sticky{
		position: fixed;
		top: 1rem;
		left: 0;
		overflow-y: scroll;
		max-height: 100%;
		margin-left: 10.5rem;
		padding-bottom: 2rem;
	}
}
*/