.infografika-termlog {
	counter-reset: lepes;

	&-lepes {
		padding-bottom: 4em;
		counter-increment: lepes;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -50%;
			bottom: 50%;
			left: 69px;
			width: 14px;
			z-index: 1;
			background: url('../../images/infografika/pont.svg') 50% 0 repeat-y;
			background-size: contain;
		}

		&:first-child:before {
			display: none;
		}

		>* {
			position: relative;
			z-index: 2;
		}
	}

	&-picture {
		margin-right: 1em;
		flex-basis: 150px;
		width: 150px;
		position: relative;

		&:before {
			color: #fff;
			content: counter(lepes);
			font-size: 1.3em;
			font-family: $headings-font-family;
			display: block;
			background: $brand-success;
			border-radius: 50%;
			text-align: center;
			width: 1.5em;
			height: 1.5em;
			line-height: 1.6;
			position: absolute;
			top: -.75em;
			left: calc(50% - .75em);
		}
	}

	&-img {
		height: 120px;
		width: auto;
		display: block;
		margin: 0 auto;

		.kiemelt & {
			height: 150px;
		}
	}

	&-caption {
		font-style: normal !important;
		font-family: $headings-font-family;
		text-transform: uppercase;
		color: $brand-primary;

		.kiemelt & {
			font-size: 1.1em;
		}
	}
}

.infografika-ipari-park {
	max-width: 100%;
	
	text {
		font-family: $headings-font-family;
	}

	&-felirat {
		@include transition(all .4s ease-in);
		@include transform-origin(50% 100%);
	}

	&-terulet:hover &-felirat {
		//@include scale(1.3);
	}
}