/*
 * saját bootstrap flexbox kiegészítés - szdani 2017.07.18.
 * reszponzív classokat gyárt, hogy html-ben lehessen variálni a flexbox elrendezést, mint az alap bootstrap grid classokkal is (pl. col- classokkal)
 *
 */

.container-flex {
	@extend .container;
}

.container-flex-fluid {
	@extend .container-fluid;
}

.row-flex {
	@extend .row;
}

.container-flex,
.container-flex-fluid,
.row-flex {
	display: flex;

	&:before, &:after {
		display: none;
	}
}

/* class definíciók, ide lehet írni, ha még kell valami, vagy valamit ki kell egészíteni */

$class-definitions: (
	flex: (display: flex),
	block: (display: block),
	inline-block: (display: inline-block),

	flex-column: (flex-direction: column, display:flex),
	flex-column-reverse: (flex-direction: column-reverse, display:flex),
	flex-row: (flex-direction: row, display:flex),
	flex-row-reverse: (flex-direction: row-reverse, display:flex),

	flex-wrap: (flex-wrap: wrap, display:flex),
	flex-wrap-reverse: (flex-wrap: wrap-reverse, display:flex),
	flex-nowrap: (flex-wrap: nowrap, display:flex),

	flex-justify-between: (justify-content: space-between),
	flex-justify-around: (justify-content: space-around),
	flex-justify-start: (justify-content: flex-start),
	flex-justify-center: (justify-content: center),
	flex-justify-end: (justify-content: flex-end),

	flex-align-start: (align-items: flex-start),
	flex-align-center: (align-items: center),
	flex-align-end: (align-items: flex-end),
	flex-align-baseline: (align-items: baseline),
	flex-align-stretch: (align-items: stretch),

	flex-align-content-start: (align-content: flex-start),
	flex-align-content-center: (align-content: center),
	flex-align-content-end: (align-content: flex-end),
	flex-align-content-baseline: (align-content: baseline),
	flex-align-content-stretch: (align-content: stretch),

	flex-shrink-0: (flex-shrink: 0),
	flex-shrink-1: (flex-shrink: 1),

	flex-grow-0: (flex-grow: 0),
	flex-grow-1: (flex-grow: 1),
	flex-grow-2: (flex-grow: 2),
	flex-grow-3: (flex-grow: 3),

	flex-basis-auto: (flex-basis: auto),
	flex-basis-25p: (flex-basis: 25%),
	flex-basis-33p: (flex-basis: calc(100% / 3)),
	flex-basis-40p: (flex-basis: 40%),
	flex-basis-50p: (flex-basis: 50%),
	flex-basis-60p: (flex-basis: 60%),
	flex-basis-66p: (flex-basis: calc(100% / 3 * 2)),
	flex-basis-75p: (flex-basis: 75%),
	flex-basis-100p: (flex-basis: 100%)

);

// alap class, media query nélkül
@each $selector, $properties in $class-definitions {
	// xs layout nem kell media querybe, mert min-width van, azalatt meg nincs semmi, 0-tól kell indulnia
	.#{$selector},
	.#{$selector}-xs {
		@each $property, $value in $properties {
			#{$property}: #{$value};
		}
	}
}

$screens: (
	//'xs': $screen-xs-min, //xs nem media querybe kell
	'sm': $screen-sm-min,
	'md': $screen-md-min,
	'lg': $screen-lg-min
);

// bootstrap screenek alapján reszponzív classok
@each $screen-name, $screen-width in $screens {
	@media (min-width: #{$screen-width}) {
		@each $selector, $properties in $class-definitions {
			.#{$selector}-#{$screen-name} {
				@each $property, $value in $properties {
					#{$property}: #{$value};
				}
			}
		}
	}
}