.fooldal-slider {
  position: relative;
}

.fooldal-slider-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: url("../../images/transsped-logo.png") 50% 50% no-repeat;
}

.fooldal-slider-btns {
  margin-left: 75%;
  width: 200px;
}

.fooldal-slider-btn {
  @include button-variant($brand-primary, #fff, #fff);
  font-size: 20px;
  margin: 13px 0;
  padding: 10px 10px 5px;
  &:hover{
    background-color: #00b98b;
    border-color: #00b98b;
    color: #eee;
  }
}

.fooldal-ajanlok {
  background: #f7f7f7;
  position: relative;
  z-index: 2;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #f7f7f7;
  }
}

.fooldal-ajanlo {
  //flex: 0 0 50%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.fooldal-ajanlo-title {
  font-size: 20px;
  margin-top: 0;
}

.fooldal-ajanlo .fooldal-ajanlo-btn {
  align-self: flex-end;
}

.fooldal-section {
  padding-bottom: 50px;
}

.fooldal-section-header {
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

.fooldal-section-title {
  font-size: 36px;
  color: $brand-primary;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 20px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
    width: 100px;
    height: 3px;
    background: $brand-success;
  }
}

.fooldal-szolgaltatasaink {
  background: #fff;
  position: relative;
}

.fooldal-szolgaltatas {
  //flex: 0 0 25%;
  display: flex;
  flex-direction: column;
}

.fooldal-szolgaltatas-nev {
  font-size: 18px;
  text-align: center;
}

.fooldal-szolgaltatas-ikon {
  height: 120px;
  margin: 2rem 0 0 0;

}

.fooldal-szolgaltatasaink-elsosor {

  .fooldal-szolgaltatas{
    &:nth-of-type(1){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/1/ikon/ikon_kamion.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-01.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
        }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-01_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(2){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/2/ikon/ikon_targonca.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-06.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-06_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(3){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/3/ikon/ikon_gyar.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-04.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-04_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(4){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/4/ikon/ikon_hajo_repulo.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-02.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-02_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
  }
  .fooldal-szolgaltatas-nev {
    font-size: 15px;
  }

  .fooldal-szolgaltatas-ikon {
    //height: 80px !important; 
  }
}

.fooldal-szolgaltatasaink-masodiksor {

  .fooldal-szolgaltatas{
    &:nth-of-type(1){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/5/ikon/ikon_vam.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-03.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-03_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(2){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/6/ikon/ikon_raktar.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-07.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-07_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(3){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/7/ikon/hammer.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-05.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-05_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
    &:nth-of-type(4){
      .fooldal-szolgaltatas-ikon{
        //background-image: url(/userfiles/szolgaltatas/8/ikon/elogistics_cart_2.svg);
        background-image: url(/userfiles/szolg_ikonok/ts_ikonok-08.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 285px;
      }
      &:hover{
        .fooldal-szolgaltatas-ikon {
          background-image: url(/userfiles/szolg_ikonok/ts_ikonok-08_hover.svg);
        }
        .fooldal-szolgaltatas-nev{
          color: #00b98b;
        }
      }
    }
  }

  margin-top: 50px;

  .fooldal-szolgaltatas-nev {
    font-size: 16px;
  }

  .fooldal-szolgaltatas-ikon {
    //height: 80px !important;
  }
}

.fooldal-kiket-keresunk {
  background: #f7f7f7;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #fff;
    z-index: 20;
  }
}

.fooldal-kiket-keresunk-header {
  position: relative;
  z-index: 10;
  border-bottom: 3px solid $brand-success;

  .fooldal-section-title {
    margin-top: 50px;
    margin-bottom: 1em;
	  font-size: 30px;

    &:after {
      left: 0;
    }
  }
}

.fooldal-kiket-keresunk-kep {
  max-width: 50vw;
  margin-top: -2vw;
}

.fooldal-kiket-keresunk-lead {
  font-size: 16px;
  width: 570px;
  color: #666;

	p:last-child {
		margin-bottom: 0;
	}
}

.fooldal-gyorsjelentkezes {
  background: #f7f7f7;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #f7f7f7;
  }
}

.fooldal-gyorsjelentkezes-ikon {
  border: 3px solid $brand-primary;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 70px;
  }
}

.fooldal-gyorsjelentkezes-pozicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 33%;
}

.fooldal-gyorsjelentkezes-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 1em;
  height: 22px * $headings-line-height * 2;
  display: flex;
  align-items: center;
}

.fooldal-gyorsjelentkezes-tovabbi-btn {
  font-size: 22px;
  margin-top: 70px;
}

.terkep-telephelyek {
  position: relative;
}

.terkep-telephely {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 5;

  &.active {
    z-index: 10;

    .terkep-telephely-bullet {
      box-shadow: 0 0 0 7px $brand-primary;
    }

    .terkep-telephely-popover {
      display: block;
    }
  }
}

.terkep-telephely-bullet {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $brand-success;
  margin: 7px 10px;
  @include transition(all .3s ease-in-out);

  &:hover + .terkep-telephely-nev {
    color: $brand-success;
  }

  .aloldal-cikk & {
    width: 15px;
    height: 15px;
  }
}

.terkep-telephely-nev {
  position: absolute;
  @include user-select(none);
  @include transition(all .25s);

  &:hover {
    color: $brand-success;
  }

  &.jobbra {
    left: 100%;
  }

  &.balra {
    right: 100%;
  }

  &.alul {
    top: 100%;
    left: 50%;
    @include translate(-50%, 0);
  }

  &.felul {
    bottom: 100%;
    left: 50%;
    @include translate(-50%, 0);
  }

  .aloldal-cikk & {
    font-size: .95em;
  }
}

.terkep-telephely-popover {
  position: absolute;
  bottom: calc(100% + 30px);
  left: 17px;
  background: #fff;
  display: none;
  padding: 15px;
  cursor: default;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  @include translate(-50%, 0);

  &.up:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
  }
  &.down:after {
    content: '';
    display: block;
    position: absolute;
    top: -6%;
    transform: rotate(180deg);
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
  }
}
.terkep-telephely-popover.bottom:after{
  content: '';
  display: block;
  position: absolute;
  top: -10%;
  left: calc(50% - 10px);
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #fff;
}

.terkep-telephely-popover-text {
  height: 12rem;
  width: 30rem;
  padding: 1rem;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;

  .telefon{
    width: 25rem;
  }
}

.terkep-telephely-szolgaltatasok {
  margin: 0 0 0;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
  list-style: outside none;
  display: flex;

  &:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }

  > li {
    padding: 0 5px;
  }

  > li:first-child {
    padding-left: 0;
  }

  > li:last-child {
    padding-right: 0;
  }
}

.terkep-telephely-szolgaltatas-link {
  &:hover, &:focus {
    svg path {
      fill: $brand-success;
    }
  }
}

.terkep-telephely-szolgaltatas-ikon {
  height: 30px;
}

.terkep-telephely-popover-nev {
  margin-top: 0;
  font-size: 16px;
  text-align: center;
}

.terkep-telephely-hr {
  border-top-width: 2px;
  margin: 4em 0;
}

.fooldal-telephely-listak {
  .fooldal-section-title {
    text-transform: none;
    font-size: 18px;

    &:after {
      left: 0;
    }
  }

  header {
    height: 7em;
  }
}

@media only screen and (max-width: 1199px){
  .terkep-telephelyek{
    display: none;
  }
}

@media only screen and (max-width: 767px){
  .fooldal-szolgaltatas-ikon{
    background-size: 190px !important;
    }
}