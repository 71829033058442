.menedzsment-listaelem {
	margin-bottom: 3em;
}

.menedzsment-listaelem-nev {
	text-transform: none;
	text-align: center;
}

.menedzsment-listaelem-beosztas {
	color: #808080;
	text-align: center;
	font-size: 1.1em;
}